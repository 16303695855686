import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Contact = () => (
  <Layout>
    <Seo title="contact" />
    <tr>
      <td colSpan={2}>Don't call us, we'll call you. (No we won't.)</td>
    </tr>
  </Layout>
);

export default Contact;
